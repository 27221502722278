import { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Chip,
  Button,
  CircularProgress,
} from '@mui/material';
import QuestionIcon from '@mui/icons-material/Help';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProcessingIcon from '@mui/icons-material/Build';
import DoneIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/SwapVerticalCircle';

import { MirrorSyncStatus } from '../../models/MirrorSyncStatus';
import AdminSvc from '../../services/Admin';

const MirrorPanel = () => {

  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<MirrorSyncStatus | null>(null);

  const loadStatus = () => {
    setLoading(true);
    return AdminSvc
      .getMirrorSyncStatus()
      .then(setStatus)
      .then(() => setLoading(false));
  };

  useEffect(() => {
    loadStatus();
  }, []);

  const runSync = () => {
    setLoading(true);
    AdminSvc
      .runMirroSync()
      .then(() => { console.log('la'); })
      .then(loadStatus);
  };

  return (
    <Paper sx={{ m: 2, p: 2, mt: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          Mirror Environment
        </Typography>
        <Box display="flex" alignItems="center">
          {isLoading && (
            <CircularProgress size={25} style={{ marginRight: 10 }} />
          )}
          <Button
            startIcon={<RefreshIcon />}
            variant='contained' color='secondary'
            onClick={loadStatus}
            disabled={isLoading}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Grid container>
          <Grid item xs={2}>
            <Typography>
              Mirror sync status:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {status?.status === 'unknown' && (
                <Chip label="Unknown" avatar={<QuestionIcon />} size="small" />
              )}
              {status?.status === 'processing' && (
                <Chip label="Processing" avatar={<ProcessingIcon style={{ color: "white" }} />} size="small" style={{ color: "white", backgroundColor: "#F4BF3F" }} />
              )}
              {status?.status === 'done' && (
                <Chip label="Done" avatar={<DoneIcon style={{ color: "white" }} />} size="small" style={{ color: "white", backgroundColor: "green" }} />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Typography>
              Last mirror refresh:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="caption">
              {status?.lastSync !== null && status?.lastSync !== null && (
                <>{status?.lastSync.toString().substring(0, 10)} {status?.lastSync.toString().substring(11, 19)} UTC</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Button
          startIcon={<SyncIcon />}
          variant='contained' color='primary'
          onClick={runSync}
          disabled={isLoading || status?.status === 'processing'}
        >
          Run mirror sync
        </Button>
      </Box>
    </Paper>
  );
};

export default MirrorPanel;
